<template>
  <div class="merlends">
    <Platesdnt :eidt="true" />
  </div>
</template>

<script>
import Platesdnt from "./components/Platesdnt.vue";
export default {
  name: "",
  components: { Platesdnt },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
</style>