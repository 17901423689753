<template>
  <div style="font-size: 14px">
    <div class="fx" style="margin-bottom: 20px">
      <div class="fx-1">
        <span style="font-weight: bold">编号:</span>
        <span style="color: #666; margin: 0px 10px">{{ id }}</span>
      </div>
    </div>
    <div class="titlxtd">审核信息</div>
    <div class="cardes">
      <div>
        <span>审批处理状态：</span>
        <div class="lusdet">
          {{ fromItem.auditStatus | audidsType }}
        </div>
      </div>
      <div><span>完成处理时间：</span> {{ fromItem.examineTime }}</div>
      <div>
        <span>处理人：</span>{{ fromItem.operatorName || "-" }}/{{
          fromItem.operatorAccount || "-"
        }}
      </div>
      <div><span>备注：</span>{{ fromItem.remark }}</div>
    </div>
    <div class="titlxtd">提交信息</div>
    <div class="cardes">
      <div>
        <span>提交类型：</span>
        <div class="lusdet">
          {{ fromItem.auditType == 1 ? "更新图片" : "—" }}
        </div>
      </div>
      <div><span>提交时间：</span> {{ fromItem.createTime }}</div>
      <div>
        <span>提交人： </span>{{ fromItem.creatorName || "-" }}/{{
          fromItem.creatorAccount || "-"
        }}
      </div>
    </div>
    <div class="titlxtd">商品基本信息</div>
    <div class="cardes">
      <div><span>商品名称：</span> {{ fromItem.name || "-" }}</div>
      <div><span>生产厂家:</span>{{ fromItem.manufacturer || "-" }}</div>
      <div><span>商品编号:</span>{{ fromItem.id || "-" }}</div>
      <div>
        <span>药品上市许可持有人:</span
        >{{ fromItem.marketingAuthorizationHolder || "-" }}
      </div>
      <div><span>批准文号:</span>{{ fromItem.approvalNumber || "-" }}</div>
      <div><span>产地:</span>{{ fromItem.placeOrigin || "-" }}</div>
      <div><span>规格 单位:</span>{{ fromItem.specification || "-" }}</div>
      <div><span>经营类别:</span>{{ fromItem.businessscopestring || "-" }}</div>
    </div>
    <div class="titlxtd">商品图片</div>
    <div>
      <div class="goods-img-box">
        <div class="img-title">修改前：</div>
        <div class="goods-img" v-if="fromItem.pictureUrlBefore">
          <div v-if="fromItem.pictureUrlBefore.length > 0">
            <div
              v-for="item in fromItem.pictureUrlBefore"
              :key="item"
              style="display: inline-block"
              class="rela marle_10"
            >
              <el-image
                style="width: 100px; height: 100px; border-radius: 10px"
                :src="item"
                :preview-src-list="[item]"
              >
              </el-image>
            </div>
          </div>
        </div>
      </div>
      <!-- 修改后 -->
      <div class="goods-img-box">
        <div class="img-title">修改后：</div>
        <div class="goods-img" v-if="fromItem.pictureUrlAfter">
          <div
            v-for="item in fromItem.pictureUrlAfter"
            :key="item"
            style="display: inline-block"
            class="rela marle_10"
          >
            <el-image
              style="width: 100px; height: 100px; border-radius: 10px"
              :src="item"
              :preview-src-list="[item]"
            >
            </el-image>
          </div>
        </div>
      </div>
    </div>
    <div
      style="text-align: center; margin-top: 20px"
      v-if="eidt && fromItem.auditStatus == 1"
    >
      <el-button @click="$router.go(-1)">返 回</el-button>
      <el-button @click="rejected" type="danger">审核驳回</el-button>
      <el-button @click="byreview" type="primary">审核通过</el-button>
    </div>
    <div style="text-align: center; margin-top: 20px" v-else>
      <el-button @click="$router.go(-1)">返 回</el-button>
    </div>
    <!-- 审核驳回提醒 -->
    <el-dialog
      title="审核驳回提醒"
      :visible.sync="dialogVisible"
      width="450px"
      :close-on-click-modal="false"
    >
      <div>
        <div>确定驳回当前操作？请在驳回原因中注明。</div>
        <div class="fx" style="margin-top: 20px">
          <div><span class="red">*</span>驳回原因：</div>
          <div style="flex: 1">
            <el-input
              type="textarea"
              :rows="7"
              maxlength="200"
              show-word-limit
              placeholder="例：图片与商品不符"
              v-model="tasfrom.remark"
            >
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false"> 取 消 </el-button>
        <el-button @click="onrejectint" type="primary"> 驳 回 </el-button>
      </span>
    </el-dialog>
    <!-- 审核通过确认 -->
    <el-dialog
      title="审核通过确认"
      :visible.sync="dialogonseds"
      width="450px"
      :close-on-click-modal="false"
    >
      <div>
        <div>是否同步更新所有供应商对应商品图片</div>
        <div style="margin-top: 20px">
          <el-radio-group v-model="tasfrom.is_sync">
            <el-radio :label="1">同步更新</el-radio>
            <el-radio :label="0">不更新</el-radio>
          </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogonseds = false"> 取 消 </el-button>
        <el-button @click="onbyreviets" type="primary"> 确认操作 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const Goods = createNamespacedHelpers("Goods");
import {
  platformGoodsAuditdetail, // 商品资料审核详情
  platformGoodsAuditaudit, // 商品资料审核
} from "@/api/apiAll/phpUrl";
export default {
  name: "",
  props: {
    // 是否编辑
    eidt: {
      type: Boolean,
      default: "false",
    },
  },
  data() {
    return {
      id: "",
      fromItem: {
        pictureUrlBefore: [], //修改前图片
        pictureUrlAfter: [], //修改后图片
      },
      dialogVisible: false,
      tasfrom: {
        status: "", //审核状态，2通过，3不通过
        remark: "", //驳回原因
        is_sync: "", //是否同步所有供应商，0否1是。审核通过的时候必填
      },
      dialogonseds: false,
      goodsId: "",
    };
  },
  filters: {
    audidsType(val) {
      switch (val) {
        case 1:
          return "待审核";
        case 2:
          return "已通过";
        case 3:
          return "已驳回";
        default:
          return "-";
      }
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.goodsId = this.$route.query.goodsId;
    this.getItem();
  },
  methods: {
    ...Goods.mapActions([
      "getPlatformGoodsDetail", //商品资料库-商品资料详情
    ]),
    /** 获取详情数据 */
    async getItem() {
      var data1 = await this.getPlatformGoodsDetail({
        id: this.goodsId,
      });
      var data2 = await platformGoodsAuditdetail({ id: this.id });
      var data = {  ...data2.data,...data1.content };
      this.fromItem = data;
    },
    //** 审核驳回 */
    rejected() {
      Object.assign(this.tasfrom, this.$options.data().tasfrom);
      this.tasfrom.status = 3;
      this.dialogVisible = true;
    },
    onrejectint() {
      var data = { ...this.tasfrom };
      data.id = this.id;
      if (data.remark == "") return this.$message.error("请填写驳回原因");
      this.formGooddes(data);
    },
    //** 审核通过 */
    byreview() {
      Object.assign(this.tasfrom, this.$options.data().tasfrom);
      this.tasfrom.status = 2;
      this.dialogonseds = true;
    },
    onbyreviets() {
      var data = { ...this.tasfrom };
      data.id = this.id;
      if (data.is_sync === "") return this.$message.error("请选择是否同步更新");
      this.formGooddes(data);
    },
    async formGooddes(data) {
      await platformGoodsAuditaudit(data).then((res) => {
        if (res.code == 200) {
          this.dialogVisible = false;
          this.dialogonseds = false;
          this.$message.success("操作成功");
          this.$router.go(-1);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.yelcard {
  display: inline-block;
  width: 50px;
  height: 15px;
  background: rgba(253, 233, 207);
}
.cardes {
  display: flex;
  flex-wrap: wrap;
  color: #555;
  margin-bottom: 20px;
  .lusdet {
    display: inline-block;
    padding: 4px 7px;
    font-size: 13px;
    text-align: center;
    color: #fff;
    background: #06b7ae;
    border-radius: 5px;
  }
  .business_type {
    font-size: 14px;
    padding: 3px 8px;
    // height: 24px;
    display: inline-block;
    border-radius: 4px;
    margin-left: 5px;
    margin-top: 5px;
    color: #00bfbf;
    background-color: rgba($color: #00bfbf, $alpha: 0.09);
  }
}
.cardes > div {
  width: 50%;
  box-sizing: border-box;
  padding: 10px;
}
.cardes > div > span {
  display: inline-block;
  width: 100px;
  color: #000;
  font-weight: bold;
}
.cardsind {
  display: flex;
  padding-top: 20px;
  .cardsone {
    border: 1px solid #eee;
    padding: 10px;
    flex: 1;
  }
  .cardsone > div:first-child {
    border-top: 0px;
  }
  .cardsone > div {
    padding: 20px 20px 10px 20px;
    margin-bottom: 10px;
    border-top: 1px dotted #eee;
    .photo {
      margin-top: 10px;
      width: 120px;
      height: 120px;
      overflow: hidden;
    }
    .leftQual {
      margin-left: 10px;
      line-height: 20px;
      color: #555;
      .text {
        margin-bottom: 10px;
      }
      .leftxt {
        display: inline-block;
        width: 100px;
        font-weight: bold;
        color: #000;
      }
    }
  }
  .cardstwo {
    border: 1px solid #eee;
    padding: 10px;
    width: 45%;
    min-width: 550px;
    .cardstrdsa {
      padding: 5px 20px 0px 20px;
      .cardse {
        display: flex;
        align-items: center;
        margin: 5px 0px;
        .title {
          width: 180px;
        }
      }
      .cardse > div {
        margin: 0px 5px;
      }
    }
    .cardstrdson {
      padding: 10px;
    }
  }
}
.ChangCss {
  background: rgba(253, 233, 207);
  border-radius: 5px;
}
.onsdes {
  text-align: center;
  margin-left: 275px;
}
.goods-img-box {
  width: 100%;
  .img-title {
    line-height: 30px;
  }
  .el-image {
    margin-right: 5px;
  }
  .goods-img {
    padding-bottom: 10px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
  }
  .cause {
    font-size: 14px;
  }
}
</style>
